import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase, camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import {apmContractPartnerRegionTypes, apmContractPartnerContractStatusTypes, apmTemporaryRestrictionCategoryTypes, apmACEPartnerServiceTypes, ampVehicleWeightTypes, apmVehicleStorageServiceTypes, apmContractPartnerAdditionalServiceTypes, apmContractPartnerExternalCommissionerTypes, apmContractPartnerAdditionalFeatureTypes, apmContractPartnerAssignmentChannelTypes, europeanCountries, apmContractPartnerServiceTonnageTypes, apmContractPartnerVehicleCompatibilityTypes, apmContractPartnerEquipmentTypes, apmContractPartnerUndergroundParkingTypes, apmRentalCarServiceOptionTypes, alfClientTypes} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {Badge, NoResultsBlock, Paginator, Panel, useStyles, ButtonIcon, Pill, RangeInput, ButtonPrimary} from '@ace-de/ui-components';
import {InputField, SelectField, MultiSelectField, Option, MultiSelectOption, Checkbox} from '@ace-de/ui-components/form';
import {Icon, waitingIcon, InteractiveIcon, findCaseIcon, searchIcon, resetIcon, questionmarkIcon, noServiceIcon, otherIcon, arrowDownIcon, arrowUpIcon, downloadIcon, plusIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableCaption, TableCell, TableHead, TableRow} from '@ace-de/ui-components/data-elements';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import config from '../config';
import routePaths from '../routePaths';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import vehicleTransportsDistances from './vehicleTransportDistances';
import germanStateTypes from './germanStateTypes';
import germanStateCodes from './germanStateCodes';
import getPrice from '../utils/getPrice';

const multiselectFields = [
    'regions',
    'contractStatus',
    'states',
    'serviceTypes',
    'compatibilities',
    'serviceAreaCountries',
    'transportationOptions',
    'vehicleStorage',
    'additionalServices',
    'additionalFeatures',
    'externalCommissioners',
    'assignmentChannels',
    'relation',
    'equipment',
    'undergroundParking',
    'rentalCarOptions',
    'carDismantling',
];

const basicServiceTypes = [
    apmACEPartnerServiceTypes.TOWING,
    apmACEPartnerServiceTypes.RECOVERY,
    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
];

const availableAssignmentChannels = [
    apmContractPartnerAssignmentChannelTypes.DRIVER_APP,
    apmContractPartnerAssignmentChannelTypes.EMAIL_PDF_XML,
    apmContractPartnerAssignmentChannelTypes.FAX,
    apmContractPartnerAssignmentChannelTypes.MANUAL_PHONE,
];

const carDisposalOptionTypes = {
    CAR_DISMANTLING: 'CAR_DISMANTLING',
    USED_CAR_STATION: 'USED_CAR_STATION',
};

const transportationOptionTypes = {
    WITH_PASSENGER: 'WITH_PASSENGER',
    WITHOUT_PASSENGER: 'WITHOUT_PASSENGER',
};

const relationOptionTypes = {
    PARENT: 'PARENT',
    CHILD: 'CHILD',
    HAS_OPERATING_UNIT: 'HAS_OPERATING_UNIT',
};

const sortingOptions = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const initialContractPartnerFilteringParams = {
    searchTerm: '',
    sort: `address.postCode,${sortingOptions.ASC}`,
    regions: '',
    contractStatus: '',
    fixedAreaOfApplication: '',
    postCodeFrom: '',
    postCodeTo: '',
    states: '',
    serviceTypes: '',
    tonnageThreshold: '',
    compatibilities: '',
    serviceAreaCountries: '',
    transportationOptions: '',
    distanceThreshold: '',
    vehicleStorage: '',
    additionalServices: '',
    additionalFeatures: '',
    externalCommissioners: '',
    relation: '',
    ellaActive: '',
    assignmentChannels: '',
    fixedPrice: [config.MIN_CONTRACT_PARTNER_FIXED_PRICE, config.MAX_CONTRACT_PARTNER_FIXED_PRICE],
    isFixedPriceEnabled: false,
    equipment: '',
    undergroundParking: '',
    rentalCarOptions: '',
    hasWaitingArea: '',
    hasCamper: '',
    carDismantling: '',
};

const ContractPartnerSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translateScreen = createTranslateShorthand('contract_partner_search_screen');
    const {history, contractPartnerSearchResults, contractPartnerSearchResultsCount} = props;
    const {initiateDownloadCPOverview, initiateBCContactModalFlow, accountManagers} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams());
    const paginatorCount = Math.ceil(contractPartnerSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);
    const [formData, setFormData] = useState(initialContractPartnerFilteringParams);
    const topElementRef = useRef(null);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
                // handle new query parameters and update form data
                const multiselectFieldsValues = {};
                multiselectFields.forEach(fieldName => {
                    multiselectFieldsValues[fieldName] = newQueryParams.getAll(fieldName).length
                        ? newQueryParams.getAll(fieldName) : '';
                });

                const fixedPriceFrom = newQueryParams.get('fixedPriceFrom') || config.MIN_CONTRACT_PARTNER_FIXED_PRICE;
                const fixedPriceTo = newQueryParams.get('fixedPriceTo') || config.MAX_CONTRACT_PARTNER_FIXED_PRICE;

                setFormData({
                    searchTerm: newQueryParams.get('searchTerm') || '',
                    sort: newQueryParams.get('sort') || `address.postCode,${sortingOptions.ASC}`,
                    fixedAreaOfApplication: newQueryParams.get('fixedAreaOfApplication')
                        ? (newQueryParams.get('fixedAreaOfApplication') === 'true') : '',
                    postCodeFrom: newQueryParams.get('postCodeFrom') || '',
                    postCodeTo: newQueryParams.get('postCodeTo') || '',
                    ...multiselectFieldsValues,
                    ellaActive: newQueryParams.get('ellaActive')
                        ? (newQueryParams.get('ellaActive') === 'true') : '',
                    hasWaitingArea: newQueryParams.get('hasWaitingArea')
                        ? (newQueryParams.get('hasWaitingArea') === 'true') : '',
                    hasCamper: newQueryParams.get('hasCamper')
                        ? (newQueryParams.get('hasCamper') === 'true') : '',
                    fixedPrice: [fixedPriceFrom, fixedPriceTo],
                    isFixedPriceEnabled: newQueryParams.get('isFixedPriceEnabled')
                        ? (newQueryParams.get('isFixedPriceEnabled') === 'true') : false,
                    tonnageThreshold: newQueryParams.get('tonnageThreshold')
                        ? parseFloat(newQueryParams.get('tonnageThreshold')) : '',
                    distanceThreshold: newQueryParams.get('distanceThreshold')
                        ? parseFloat(newQueryParams.get('distanceThreshold')) : '',
                });
            }
        }
    }, [history.location.search, queryParams]);

    const openContractPartnerScreen = contractPartner => {
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER, {
            contractPartnerId: contractPartner.id,
        }));
    };

    const handlePaginationPage = page => {
        topElementRef.current?.scrollIntoView({behavior: 'smooth'});
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: queryParamsString}));
    };

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            // handle fixedPrice parameters
            if (formField === 'fixedPrice' && Array.isArray(formData[formField])) {
                const fixedPrice = formData[formField];
                if (formData['isFixedPriceEnabled']) {
                    apiQueryParams.append('fixedPriceFrom', fixedPrice[0]);
                    apiQueryParams.append('fixedPriceTo', fixedPrice[1]);
                }
                return;
            }

            // handle multiselect fields
            if (multiselectFields.includes(formField) && Array.isArray(formData[formField])) {
                formData[formField].forEach(fieldValue => {
                    if (fieldValue) {
                        apiQueryParams.append(`${formField}`, fieldValue);
                    }
                });
                return;
            }

            if (formData[formField] !== undefined && formData[formField] !== '') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const onSubmit = () => {
        if (!formData) return;

        const apiQueryParams = formatQueryParams({
            ...formData,
        });

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: queryParamsString}));
    };

    const onChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleResetFilter = () => {
        setFormData(initialContractPartnerFilteringParams);

        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: ''}));
    };

    const handleDownloadTable = () => {
        const apiQueryParams = formatQueryParams(formData);

        if (apiQueryParams) {
            apiQueryParams.append('country', config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE);
        }

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        initiateDownloadCPOverview({
            url: config.ACE_APM_CONTRACT_PARTNERS_OVERVIEW_EXPORT_ENDPOINT + `?${queryParamsString}`,
            fileName: `${translateScreen('file.name')}.xlsx`,
        });
    };

    const categoryIconMap = {
        [apmTemporaryRestrictionCategoryTypes.RESTRICTED_SERVICES]: questionmarkIcon,
        [apmTemporaryRestrictionCategoryTypes.TEMPORARILY_CLOSED]: noServiceIcon,
        [apmTemporaryRestrictionCategoryTypes.WAITING_TIME]: waitingIcon,
        [apmTemporaryRestrictionCategoryTypes.OTHER]: otherIcon,
    };

    return (
        <Fragment>
            <Panel
                title={translateScreen('panel_title.filter_and_search')}
                contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--direction-column')}
                ref={topElementRef}
            >
                <Fragment>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                        <div
                            className={cx('global!ace-u-grid-column--span-7')}
                            onKeyDown={event => {
                                if (event.key === 'Enter') onSubmit();
                            }}
                        >
                            <InputField
                                name="searchTerm"
                                value={formData?.searchTerm}
                                label={translateScreen('input_field_label.free_search')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                onChange={value => onChange('searchTerm', value)}
                            />
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <MultiSelectField
                                name="contractStatus"
                                value={formData?.contractStatus}
                                label={translateScreen('select_field_label.status')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                onChange={value => onChange('contractStatus', value)}
                            >
                                {Object.keys(apmContractPartnerContractStatusTypes).map(statusType => (
                                    <MultiSelectOption
                                        key={statusType}
                                        value={statusType}
                                        name={`contractStatusOption${camelCase(statusType)}`}
                                    >
                                        {translate(`global.contract_status.${snakeCase(statusType)}`)}
                                    </MultiSelectOption>
                                ))}
                            </MultiSelectField>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <SelectField
                                name="sort"
                                label={translateScreen('select_field_label.sort')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                value={formData?.sort}
                                onChange={value => onChange('sort', value)}
                            >
                                {Object.keys(sortingOptions)
                                    .map(sortingOption => (
                                        <Option
                                            key={sortingOption}
                                            value={`address.postCode,${sortingOption}`}
                                            name={`sortingOption${camelCase(sortingOption)}`}
                                        >
                                            {translateScreen(`select_field_option.${sortingOption.toLowerCase()}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-grid-column--span-1',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                                'global!ace-u-padding--top-24',
                            ])}
                        >
                            <ButtonIcon
                                name="searchButton"
                                icon={searchIcon}
                                onClick={onSubmit}
                                className={cx('global!ace-u-margin--top-8')}
                            />
                        </div>
                    </div>
                    {areFiltersExpanded && (
                        <Fragment>
                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.region')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <MultiSelectField
                                    name="regions"
                                    value={formData?.regions}
                                    label={translateScreen('select_field_label.region')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('regions', value)}
                                >
                                    {Object.keys(apmContractPartnerRegionTypes).map(region => (
                                        <MultiSelectOption
                                            key={region}
                                            value={region}
                                            name={`regionOption${camelCase(region)}`}
                                        >
                                            {translate(`global.region.${snakeCase(region)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <InputField
                                    name="postCodeFrom"
                                    value={formData?.postCodeFrom}
                                    label={translateScreen('input_field_label.post_code_area_from')}
                                    className={cx('global!ace-u-width--128')}
                                    placeholder={translateScreen('input_field_placeholder.from_post_code', {postCodeFrom: config.POST_CODE_FROM})}
                                    onChange={value => onChange('postCodeFrom', value)}
                                />
                                <InputField
                                    name="postCodeTo"
                                    value={formData?.postCodeTo}
                                    label={translateScreen('input_field_label.post_code_area_to')}
                                    className={cx('global!ace-u-width--128')}
                                    placeholder={translateScreen('input_field_placeholder.to_post_code', {postCodeTo: config.POST_CODE_TO})}
                                    onChange={value => onChange('postCodeTo', value)}
                                />
                                <MultiSelectField
                                    name="states"
                                    value={formData?.states}
                                    label={translateScreen('select_field_label.state')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('states', value)}
                                >
                                    {Object.keys(germanStateTypes).map(stateKey => (
                                        <MultiSelectOption
                                            key={stateKey}
                                            value={germanStateCodes[stateKey]} // the value is a state code!
                                            name={`stateOption${camelCase(stateKey)}`}
                                        >
                                            {translate(`global.german_state.${snakeCase(stateKey)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                            </div>

                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.basic_services')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <MultiSelectField
                                    name="serviceTypes"
                                    value={formData?.serviceTypes}
                                    label={translateScreen('select_field_label.service')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('serviceTypes', value)}
                                >
                                    {basicServiceTypes.map(service => (
                                        <MultiSelectOption
                                            key={service}
                                            value={service}
                                            name={`serviceOption${camelCase(service)}`}
                                        >
                                            {translate(`global.basic_service_type.${snakeCase(service)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <SelectField
                                    name="tonnageThreshold"
                                    value={formData?.tonnageThreshold}
                                    label={translateScreen('select_field_label.tonnage')}
                                    className={cx('global!ace-u-width--128')}
                                    onChange={value => onChange('tonnageThreshold', value)}
                                >
                                    <Option
                                        name="tonnageOptionNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    {Object.keys(apmContractPartnerServiceTonnageTypes)
                                        .filter(tonnageType => {
                                            return tonnageType !== apmContractPartnerServiceTonnageTypes.NO_SERVICES;
                                        })
                                        .map(tonnageType => (
                                            <Option
                                                key={tonnageType}
                                                value={tonnageType === apmContractPartnerServiceTonnageTypes
                                                    .ULTRA_HEAVY_WEIGHT_VEHICLE
                                                    ? config.MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE
                                                    : ampVehicleWeightTypes[tonnageType]}
                                                name={`tonnageOption${camelCase(tonnageType)}`}
                                            >
                                                {translate(`global.vehicle_tonnage_type.${snakeCase(tonnageType)}`)}
                                            </Option>
                                        ))}
                                </SelectField>
                                <MultiSelectField
                                    name="compatibilities"
                                    value={formData?.compatibilities}
                                    label={translateScreen('select_field_label.vehicle_type')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('compatibilities', value)}
                                >
                                    {Object.keys(apmContractPartnerVehicleCompatibilityTypes).map(vehicleType => (
                                        <MultiSelectOption
                                            key={vehicleType}
                                            value={vehicleType}
                                            name={`vehicleTypeOption${camelCase(vehicleType)}`}
                                        >
                                            {translate(`global.vehicle_compatibility_type.${snakeCase(vehicleType)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="serviceAreaCountries"
                                    value={formData?.serviceAreaCountries}
                                    label={translateScreen('select_field_label.foreign_countries')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('serviceAreaCountries', value)}
                                >
                                    {config.GERMAN_NEIGHBORING_COUNTRIES.map(countryCode => (
                                        <MultiSelectOption
                                            key={countryCode}
                                            value={snakeCase(europeanCountries[countryCode].name).toUpperCase()}
                                            name={`foreignCountryOption${camelCase(countryCode)}`}
                                        >
                                            {translate(
                                                `global.country.${snakeCase(europeanCountries[countryCode].name)}`,
                                            )}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.additional_services')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <MultiSelectField
                                    name="transportationOptions"
                                    value={formData?.transportationOptions}
                                    label={translateScreen('select_field_label.transport')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('transportationOptions', value)}
                                >
                                    {Object.keys(transportationOptionTypes).map(transporationOption => (
                                        <MultiSelectOption
                                            key={transporationOption}
                                            value={transporationOption}
                                            name={`transportationOption${camelCase(transporationOption)}`}
                                        >
                                            {translateScreen(`select_field_option.${snakeCase(transporationOption)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <SelectField
                                    name="distanceThreshold"
                                    value={formData?.distanceThreshold}
                                    label={translateScreen('select_field_label.distance')}
                                    className={cx('global!ace-u-width--128')}
                                    onChange={value => onChange('distanceThreshold', value)}
                                >
                                    <Option
                                        name="transportDistanceNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    {vehicleTransportsDistances
                                    // do not include 0 as an option
                                        .filter(distance => !!distance)
                                        .map(distance => (
                                            <Option
                                                key={`transportDistance${distance}`}
                                                value={distance}
                                                name={`transportDistance${distance}`}
                                            >
                                                {translate(`global.vehicle_transport_distance_type.${distance}`)}
                                            </Option>
                                        ))}
                                </SelectField>
                                <MultiSelectField
                                    name="vehicleStorages"
                                    value={formData?.vehicleStorage}
                                    label={translateScreen('select_field_label.storage')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('vehicleStorage', value)}
                                >
                                    {Object.keys(apmVehicleStorageServiceTypes).map(storage => (
                                        <MultiSelectOption
                                            key={storage}
                                            value={storage}
                                            name={`storageOption${camelCase(storage)}`}
                                        >
                                            {translate(`global.vehicle_storage_service_type.${snakeCase(storage)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="additionalServices"
                                    value={formData?.additionalServices}
                                    label={translateScreen('select_field_label.additional_services')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('additionalServices', value)}
                                >
                                    {Object.keys(apmContractPartnerAdditionalServiceTypes)
                                        .map(additionalServices => (
                                            <MultiSelectOption
                                                key={additionalServices}
                                                value={additionalServices}
                                                name={`additionalServiceOption${camelCase(additionalServices)}`}
                                            >
                                                {translate(
                                                    `global.contract_partner_additional_services.${snakeCase(
                                                        additionalServices,
                                                    )}`,
                                                )}
                                            </MultiSelectOption>
                                        ))}
                                </MultiSelectField>
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.operating_features')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <MultiSelectField
                                    name="equipment"
                                    value={formData?.equipment}
                                    label={translateScreen('select_field_label.equipment')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('equipment', value)}
                                >
                                    {Object.keys(apmContractPartnerEquipmentTypes).map(equipmentType => (
                                        <MultiSelectOption
                                            key={equipmentType}
                                            value={equipmentType}
                                            name={`equipmentOption${camelCase(equipmentType)}`}
                                        >
                                            {translate(
                                                `global.contract_partner_equipment_type.${snakeCase(equipmentType)}`,
                                            )}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="undergroundParking"
                                    value={formData?.undergroundParking}
                                    label={translateScreen('select_field_label.underground_parking')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('undergroundParking', value)}
                                >
                                    {Object.keys(apmContractPartnerUndergroundParkingTypes).map(parkingType => (
                                        <MultiSelectOption
                                            key={parkingType}
                                            value={parkingType}
                                            name={`parkingOption${camelCase(parkingType)}`}
                                        >
                                            {translate(
                                                `global.contract_partner_underground_parking_type.${snakeCase(
                                                    parkingType,
                                                )}`,
                                            )}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="rentalCarOptions"
                                    value={formData?.rentalCarOptions}
                                    label={translateScreen('select_field_label.rental_vehicles')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('rentalCarOptions', value)}
                                >
                                    {Object.keys(apmRentalCarServiceOptionTypes).map(rentalCarOption => (
                                        <MultiSelectOption
                                            key={rentalCarOption}
                                            value={rentalCarOption}
                                            name={`rentalCarOption${camelCase(rentalCarOption)}`}
                                        >
                                            {translate(
                                                `global.contract_partner_rental_car_service_option.${snakeCase(
                                                    rentalCarOption,
                                                )}`,
                                            )}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <SelectField
                                    name="hasWaitingArea"
                                    value={formData?.hasWaitingArea}
                                    label={translateScreen('select_field_label.has_waiting_area')}
                                    className={cx('global!ace-u-width--128')}
                                    onChange={value => onChange('hasWaitingArea', value)}
                                >
                                    <Option
                                        name="hasWaitingAreaOptionNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    <Option
                                        name="hasWaitingAreaOptionTrue"
                                        value={true}
                                    >
                                        {translateScreen('select_field_option.yes')}
                                    </Option>
                                    <Option
                                        name="hasWaitingAreaOptionFalse"
                                        value={false}
                                    >
                                        {translateScreen('select_field_option.no')}
                                    </Option>
                                </SelectField>
                                <SelectField
                                    name="hasCamper"
                                    value={formData?.hasCamper}
                                    label={translateScreen('select_field_label.has_camper')}
                                    className={cx('global!ace-u-width--128')}
                                    onChange={value => onChange('hasCamper', value)}
                                >
                                    <Option
                                        name="hasCamperOptionNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    <Option
                                        name="hasCamperOptionTrue"
                                        value={true}
                                    >
                                        {translateScreen('select_field_option.yes')}
                                    </Option>
                                    <Option
                                        name="hasCamperOptionFalse"
                                        value={false}
                                    >
                                        {translateScreen('select_field_option.no')}
                                    </Option>
                                </SelectField>
                                <MultiSelectField
                                    name="carDismantling"
                                    value={formData?.carDismantling}
                                    label={translateScreen('select_field_label.car_disposal')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('carDismantling', value)}
                                >
                                    {Object.keys(carDisposalOptionTypes).map(carDisposalOptionType => (
                                        <MultiSelectOption
                                            key={carDisposalOptionType}
                                            value={carDisposalOptionType}
                                            name={`carDisposalOption${camelCase(carDisposalOptionType)}`}
                                        >
                                            {translateScreen(`select_field_option.${snakeCase(carDisposalOptionType)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.assignment')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-flex-end',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <SelectField
                                    name="fixedAreaOfApplication"
                                    value={formData?.fixedAreaOfApplication}
                                    label={translate('global.operation_area.fixed_area')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('fixedAreaOfApplication', value)}
                                >
                                    <Option
                                        name="fixedAreaOptionNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    <Option
                                        name="fixedAreaOptionTrue"
                                        value={true}
                                    >
                                        {translateScreen('select_field_option.yes')}
                                    </Option>
                                    <Option
                                        name="fixedAreaOptionFalse"
                                        value={false}
                                    >
                                        {translateScreen('select_field_option.no')}
                                    </Option>
                                </SelectField>
                                <div>
                                    <Checkbox
                                        name="isFixedPriceEnabled"
                                        value={true}
                                        isSelected={formData?.isFixedPriceEnabled}
                                        onChange={value => onChange('isFixedPriceEnabled', value)}
                                    >
                                        <span
                                            className={cx([
                                                'global!ace-u-margin--top-4',
                                                'global!ace-u-typography--variant-caption',
                                            ])}
                                        >
                                            {translateScreen('range_field_label.fixed_price')}
                                        </span>
                                    </Checkbox>
                                    <RangeInput
                                        name="fixedPrice"
                                        value={formData?.fixedPrice}
                                        minValue={config.MIN_CONTRACT_PARTNER_FIXED_PRICE}
                                        maxValue={config.MAX_CONTRACT_PARTNER_FIXED_PRICE}
                                        step={10}
                                        className={cx('global!ace-u-width--256')}
                                        onChange={value => onChange('fixedPrice', value)}
                                        isDisabled={!formData?.isFixedPriceEnabled}
                                    />
                                </div>
                                <MultiSelectField
                                    name="assignmentChannels"
                                    value={formData?.assignmentChannels}
                                    label={translateScreen('select_field_label.assignment_channel')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('assignmentChannels', value)}
                                >
                                    {availableAssignmentChannels.map(channel => (
                                        <MultiSelectOption
                                            key={channel}
                                            value={channel}
                                            name={`assignmentChannelOption${camelCase(channel)}`}
                                        >
                                            {translate(
                                                `global.contract_partner_assignment_channel.${snakeCase(channel)}`,
                                            )}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                            </div>
                            <div className={cx(['global!ace-u-typography--variant-body-medium', 'global!ace-u-margin--bottom-8'])}>
                                {translateScreen('text.other')}
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--column-gap-24',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                <MultiSelectField
                                    name="relations"
                                    value={formData?.relation}
                                    label={translateScreen('select_field_label.relations')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('relation', value)}
                                >
                                    {Object.keys(relationOptionTypes).map(relation => (
                                        <MultiSelectOption
                                            key={relation}
                                            name={`relationOption${relation}`}
                                            value={relation}
                                        >
                                            {translateScreen(`select_field_option.${snakeCase(relation)}`)}
                                        </MultiSelectOption>
                                    ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="externalCommissioners"
                                    value={formData?.externalCommissioners}
                                    label={translateScreen('select_field_label.external_commissioners')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('externalCommissioners', value)}
                                >
                                    {Object.keys(apmContractPartnerExternalCommissionerTypes)
                                        .map(commissioner => (
                                            <MultiSelectOption
                                                key={`externalCommissioner${commissioner}`}
                                                value={commissioner}
                                                name={`externalCommissioner${commissioner}`}
                                            >
                                                {translate(
                                                    `global.contract_partner_external_commissioners.${snakeCase(
                                                        commissioner,
                                                    )}`,
                                                )}
                                            </MultiSelectOption>
                                        ))}
                                </MultiSelectField>
                                <MultiSelectField
                                    name="additionalFeatures"
                                    value={formData?.additionalFeatures}
                                    label={translateScreen('select_field_label.additional_features')}
                                    className={cx('global!ace-u-width--256')}
                                    onChange={value => onChange('additionalFeatures', value)}
                                >
                                    {Object.keys(apmContractPartnerAdditionalFeatureTypes)
                                        .map(additionalFeature => (
                                            <MultiSelectOption
                                                key={additionalFeature}
                                                value={additionalFeature}
                                                name={`additionalFeatureOption${camelCase(additionalFeature)}`}
                                            >
                                                {translate(
                                                    `global.contract_partner_additional_features.${snakeCase(
                                                        additionalFeature,
                                                    )}`,
                                                )}
                                            </MultiSelectOption>
                                        ))}
                                </MultiSelectField>
                                <SelectField
                                    name="ellaActive"
                                    value={formData?.ellaActive}
                                    label={translateScreen('select_field_label.ella_active')}
                                    className={cx('global!ace-u-width--128')}
                                    onChange={value => onChange('ellaActive', value)}
                                >
                                    <Option
                                        name="ellaActiveOptionNone"
                                        value=""
                                    >
                                        {translateScreen('select_field_option_placeholder.select_option')}
                                    </Option>
                                    <Option
                                        name="ellaActiveOptionTrue"
                                        value={true}
                                    >
                                        {translateScreen('select_field_option.yes')}
                                    </Option>
                                    <Option
                                        name="ellaActiveOptionFalse"
                                        value={false}
                                    >
                                        {translateScreen('select_field_option.no')}
                                    </Option>
                                </SelectField>
                            </div>
                        </Fragment>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                    >
                        <div>
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {translateScreen('label.result_count')}
                            </span>&nbsp;
                            <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                {contractPartnerSearchResultsCount}
                            </span>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <div className={cx('global!ace-u-margin--right-64')}>
                                <InteractiveIcon
                                    icon={resetIcon}
                                    className={cx([
                                        'ace-c-interactive-icon--reverse',
                                        'ace-c-interactive-icon--highlight',
                                    ])}
                                    onClick={handleResetFilter}
                                >
                                    {translateScreen('interactive_icon_label.reset_filter')}
                                </InteractiveIcon>
                            </div>
                            <div>
                                <InteractiveIcon
                                    icon={!areFiltersExpanded ? arrowDownIcon : arrowUpIcon}
                                    className={cx([
                                        'ace-c-interactive-icon--reverse',
                                        'ace-c-interactive-icon--primary',
                                        'ace-c-interactive-icon--highlight',
                                    ])}
                                    onClick={() => setAreFiltersExpanded(prevState => !prevState)}
                                >
                                    {translateScreen('interactive_icon_label.advanced_search')}
                                </InteractiveIcon>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Panel>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-margin--32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateScreen('panel_title.contract_partners')}
                    </h3>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <InteractiveIcon
                            icon={downloadIcon}
                            className={cx([
                                'ace-c-interactive-icon--reverse',
                                'ace-c-interactive-icon--highlight',
                                'global!ace-u-margin--32',
                            ])}
                            onClick={handleDownloadTable}
                            isDisabled={!contractPartnerSearchResults.length}
                        >
                            {translateScreen('interactive_icon_label.download')}
                        </InteractiveIcon>
                        {!config.IS_PRE_BC_VERSION && (
                            <ButtonPrimary
                                className={cx([
                                    'global!ace-u-margin--32',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-center',
                                ])}
                                onClick={() => initiateBCContactModalFlow({client: alfClientTypes.ACE})}
                            >
                                <Icon icon={plusIcon} className={cx('ace-c-icon--color-contrast')} />
                                {translateScreen('interactive_icon_label.create_contract_partner')}
                            </ButtonPrimary>
                        )}
                    </div>
                </div>
                <Table qaIdent="contract-partner-search-results">
                    {contractPartnerSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} qaIdentPart="cp-name">
                                {translateScreen('table_header.name')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-contract-status">
                                {translateScreen('table_header.status')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-region">
                                {translateScreen('table_header.region')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-address-post-code">
                                {translateScreen('table_header.post_code')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-address-city">
                                {translateScreen('table_header.city')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-fixed-area-of-application">
                                {translateScreen('table_header.fixed_area_of_application')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-fixed-price">
                                {translateScreen('table_header.fixed_price')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="cp-restrictions">
                                {translateScreen('table_header.restrictions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractPartnerSearchResults.length > 0
                            && contractPartnerSearchResults.map(contractPartner => {
                                const accountManager = accountManagers[contractPartner.regionId] || null;
                                return (
                                    <TableRow
                                        key={contractPartner.id}
                                        onClick={() => openContractPartnerScreen(contractPartner)}
                                        qaIdentPart={contractPartner.id}
                                    >
                                        <TableCell
                                            colSpan={4}
                                            qaIdentPart="cp-name"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {(!!contractPartner.affiliatedPartner
                                                    || !!contractPartner.hasAffiliatePartners)
                                                && (
                                                <Pill
                                                    type={!contractPartner.affiliatedPartner ? 'information-highlighted' : 'pending-highlighted'}
                                                    className={cx('global!ace-u-margin--right-24')}
                                                >
                                                    {!contractPartner.affiliatedPartner ? translateScreen('pill.relation_parent') : translateScreen('pill.relation_child')}
                                                </Pill>
                                                )
                                            }
                                            <span className={cx({
                                                'global!ace-u-margin--left-64': !contractPartner.affiliatedPartner && !contractPartner.hasAffiliatePartners,
                                            })}
                                            >{contractPartner.name || ''}
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-contract-status"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner.contractStatus
                                                ? translate(`global.contract_status.${snakeCase(contractPartner.contractStatus)}`)
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-region"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {accountManager?.region
                                                ? translate(`global.region.${snakeCase(accountManager.region)}`)
                                                : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-address-post-code"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner.address?.postCode || ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-address-city"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner.address?.city || ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-fixed-area-of-application"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner.fixedAreaOfApplication === true
                                                ? translateScreen('fixed_area_of_application_text.yes') : ''}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-fixed-price"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner?.fixedPrice
                                                ? getPrice(contractPartner.fixedPrice, activeLocale) : ''}
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            qaIdentPart="cp-restrictions"
                                            qaIdentPartPostfix={contractPartner.id}
                                        >
                                            {contractPartner.lastRestrictionType ? (
                                                <div
                                                    className={cx([
                                                        'global!ace-u-inline-flex',
                                                        'global!ace-u-flex--align-center',
                                                        'global!ace-u-flex--justify-flex-start',
                                                    ])}
                                                >
                                                    <Icon
                                                        icon={categoryIconMap[contractPartner?.lastRestrictionType]}
                                                        className={cx(['ace-c-icon--m', 'global!ace-u-margin--right-8'])}
                                                    />
                                                    <div>
                                                        {translate(
                                                            `global.temporary_restriction_category.${snakeCase(
                                                                contractPartner?.lastRestrictionType,
                                                            )}`,
                                                        )}
                                                    </div>
                                                    {typeof contractPartner.activeRestrictions === 'number'
                                                        && contractPartner.activeRestrictions > 1 && (
                                                            <Badge
                                                                className={cx([
                                                                    'ace-c-badge--status-notification',
                                                                    'global!ace-u-margin--left-8',
                                                                ])}
                                                            >
                                                                {`+${contractPartner.activeRestrictions - 1}`}
                                                            </Badge>
                                                    )}
                                                </div>
                                            ) : '-'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Panel>
            {contractPartnerSearchResults.length > 0 && (
                <Paginator
                    page={queryParams?.get('page') ? parseInt(queryParams.get('page'), 10) : 0}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

ContractPartnerSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    contractPartnerSearchResults: PropTypes.array,
    contractPartnerSearchResultsCount: PropTypes.number,
    initiateDownloadCPOverview: PropTypes.func.isRequired,
    initiateBCContactModalFlow: PropTypes.func.isRequired,
    accountManagers: PropTypes.object,
};

ContractPartnerSearchScreen.defaultProps = {
    contractPartnerSearchResults: [],
    contractPartnerSearchResultsCount: 0,
    accountManagers: {},
};

const mapStateToProps = state => {
    return {
        contractPartnerSearchResults: contractPartnerSelectors.getContractPartnerSearchResults(state),
        contractPartnerSearchResultsCount: contractPartnerSelectors.getContractPartnerSearchResultsCount(state),
        accountManagers: contractPartnerSelectors.getAccountManagers(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDownloadCPOverview: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW,
        payload,
    }),
    initiateBCContactModalFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractPartnerSearchScreen);
