import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {persistenceStates, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary, InteractiveIcon, closeIcon, SelectField, Form, Option, DateField, Pill} from '@ace-de/ui-components';
import {calendarIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';

const generateCustomTimeFieldOptions = () => {
    const options = [];
    for (let hours = 0; hours < 24; hours += 1) {
        const hoursString = hours < 10 ? `0${hours}` : hours;
        options.push(`${hoursString}:00`);
    }
    return options;
};

const ContractPartnerStatusUpdateModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, contractPartner} = props;
    const {declineContractPartnerStatusUpdate, confirmContractPartnerStatusUpdate} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('contract_partner_status_update_modal');
    const [isScheduled, setIsScheduled] = useState(!!contractPartner?.pendingStatusChange);

    const handleOnSubmit = formValues => {
        const contractPartnerData = !isScheduled
            ? {
                contractStatus: formValues.contractStatus,
            }
            : {
                status: formValues.contractStatus,
                scheduledAt: moment(formValues.scheduledDate).utc(),
            };
        confirmContractPartnerStatusUpdate({
            contractPartnerData,
        });
    };

    if (!contractPartner) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            title={translateModal('title.change_status')}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineContractPartnerStatusUpdate}
                    isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
                />
            )}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-full-width',
                    'global!ace-u-typography--variant-body',
                ])}
            >
                <div className={cx('global!ace-u-full-width')}>
                    <Form name="contractPartnerStatusUpdateForm" onSubmit={handleOnSubmit}>
                        {formValues => {
                            const isScheduledDateValid = formValues.scheduledDate
                                && moment(formValues.scheduledDate).isValid()
                                ? moment(formValues.scheduledDate)
                                    .isAfter(moment())
                                : true;
                            const defaultSchedulingDate = moment().add(1, 'day').minutes(0).seconds(0)
                                .milliseconds(0)
                                .toString();
                            return (
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-flex-start',
                                        'global!ace-u-margin--top-32',
                                    ])}
                                >
                                    <SelectField
                                        name="contractStatus"
                                        value={contractPartner.contractStatus || ''}
                                        label={translateModal('select_label.status')}
                                        className={cx('global!ace-u-full-width')}
                                    >
                                        {Object.keys(apmContractPartnerContractStatusTypes).map(status => (
                                            <Option
                                                key={status}
                                                value={status}
                                                name={status}
                                                isDisabled={status === apmContractPartnerContractStatusTypes.ACTIVE
                                                    && (!contractPartner?.location?.longitude || !contractPartner?.location?.latitude)} // eslint-disable-line max-len
                                            >
                                                {translate(`global.contract_status.${snakeCase(status)}`)}
                                            </Option>
                                        ))}
                                    </SelectField>
                                    {(!contractPartner?.location?.longitude || !contractPartner?.location?.latitude) && ( // eslint-disable-line max-len
                                        <Pill type="negative" className={cx('global!ace-u-margin--top-8')}>
                                            {translateModal('error_message.location_is_missing')}
                                        </Pill>
                                    )}
                                    <SelectField
                                        name="changeType"
                                        value={contractPartner.pendingStatusChange
                                            ? translateModal('change_type.scheduled')
                                            : translateModal('change_type.immediately')}
                                        label={translateModal('select_label.change_type')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--top-32',
                                        ])}
                                        onChange={value => setIsScheduled(value === translateModal('change_type.scheduled'))}
                                    >
                                        <Option
                                            value={translateModal('change_type.immediately')}
                                            name="immediately"
                                        >
                                            {translateModal('change_type.immediately')}
                                        </Option>
                                        <Option
                                            value={translateModal('change_type.scheduled')}
                                            name="scheduled"
                                        >
                                            {translateModal('change_type.scheduled')}
                                        </Option>
                                    </SelectField>
                                    <div
                                        className={cx([
                                            'global!ace-u-margin--top-32',
                                            'global!ace-u-width--full',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-items-flex-end',
                                            'global!ace-u-flex--justify-content-space-between',
                                        ])}
                                    >
                                        {isScheduled && (
                                            <DateField
                                                name="scheduledDate"
                                                label={translateModal('select_label.date_modification')}
                                                icon={calendarIcon}
                                                minDate={moment().format()}
                                                value={contractPartner.pendingStatusChange?.scheduledAt
                                                    ? contractPartner.pendingStatusChange.scheduledAt
                                                    : defaultSchedulingDate}
                                                format="DD.MM.YYYY, HH:mm"
                                                customTimeOptions={generateCustomTimeFieldOptions()}
                                            />
                                        )}
                                        <ButtonPrimary
                                            name="updateStatusButton"
                                            type="submit"
                                            className={cx('global!ace-u-margin--left-auto')}
                                            isDisabled={formValues.changeType === translateModal('change_type.scheduled')
                                                && (!formValues.scheduledDate || !isScheduledDateValid)}
                                        >
                                            {translateModal('button_label.save')}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            );
                        }}
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

ContractPartnerStatusUpdateModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    declineContractPartnerStatusUpdate: PropTypes.func.isRequired,
    confirmContractPartnerStatusUpdate: PropTypes.func.isRequired,
    contractPartner: PropTypes.object,
};

ContractPartnerStatusUpdateModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineContractPartnerStatusUpdate: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CONTRACT_PARTNER_STATUS_UPDATE,
    }),
    confirmContractPartnerStatusUpdate: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractPartnerStatusUpdateModal);
